
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import { API } from '~/types/api'

@Component({
  mixins: [MixinMemberId]
})
export default class MyBookshelf extends Vue {
  @Prop({ type: Number }) private articleId!: number
  @Prop({ type: Boolean, default: false }) private articlePage!: boolean
  @Prop({ type: Boolean, default: false }) private isSaved!: boolean
  @Prop({ type: String }) private used!: string

  data() {
    return {
      localSaved: false // 表示用のsave data
    }
  }

  private memberId!: number
  private localSaved: boolean = false
  private isToggling: boolean = false // ボタン連打などで、複数回処理が走るのを防止
  private message: string = ''

  // storeのmemberFollowingに変化があったらlocalSavedを更新
  @Watch('$store.state.member.memberFollowing.favoriteStoryIds', {
    immediate: true,
    deep: true
  })
  onFavoriteStoryIdsChange() {
    this.updateLocalSaved()
  }

  // mount時にlocalSavedの初期状態をVuexから取得、以降の更新は@Watchで監視
  private mounted() {
    this.updateLocalSaved()
  }

  private get saved(): boolean {
    return this.localSaved
  }

  private updateLocalSaved() {
    if (this.isToggling) return // トグル中ならスキップ

    const favoriteStoryIds =
      this.$store.state.member.memberFollowing.favoriteStoryIds || []
    this.localSaved = favoriteStoryIds.includes(this.articleId)
  }

  private toggleBookshelf(event): void {
    event.stopPropagation()
    const previewPath = /^\/preview\//.test(this.$route.path)

    if (!previewPath && !this.memberId) {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    } else if (!previewPath) {
      const beforeSaved = this.saved // APIコール前にlocalSavedの値を変更するので、トグル前の状態を記録
      this.isToggling = true
      this.localSaved = !beforeSaved
      beforeSaved ? this.delete() : this.submit()
    }
  }

  private get tooltipMessage(): string {
    return this.localSaved ? 'マイ本棚から削除' : 'マイ本棚に追加'
  }

  private get articleBottomIcon(): boolean {
    return this.used === 'article-reaction-bottom'
  }

  private async delete(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token: API.CSRF = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.deleteAction(token.csrf_token)
    } catch (e) {
      this.localSaved = true
      this.isToggling = false
    }
  }

  private async deleteAction(csrf: string): Promise<void> {
    const url = process.env.API_URL + `/members/${this.memberId}/favorite_story`
    const deleteData = {
      story_id: this.articleId
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      data: deleteData,
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$delete(url, config)
      this.localSaved = false
      this.$store.dispatch('bookshelf/changeStoryIsSaved', false)
      this.$store.dispatch('member/updateFavoriteStoryIds', this.articleId)
    } catch {
      this.localSaved = true
    } finally {
      this.isToggling = false
    }
  }

  private async submit(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token: API.CSRF = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.submitAction(token.csrf_token)
    } catch (e) {
      this.localSaved = false
      this.isToggling = false
    }
  }

  private async submitAction(csrf: string): Promise<void> {
    const url = process.env.API_URL + `/members/${this.memberId}/favorite_story`
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    const postData = {
      story_id: this.articleId
    }
    try {
      await this.$axios.$post(url, postData, config)
      this.localSaved = true
      this.$store.dispatch('bookshelf/changeStoryIsSaved', true)
      this.$store.dispatch('member/updateFavoriteStoryIds', this.articleId)
    } catch {
      this.localSaved = false
    } finally {
      this.isToggling = false
    }
  }
}
